// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.


.jarallax > .jarallax-img {
    opacity: 0.5;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-brand:hover svg path{
    color: $primary-2;
    fill: $primary-2;
}

.navbar-dark .navbar-brand svg path{
    fill: white;
}
